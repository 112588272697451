import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
//import { useNavigate , redirect} from "react-router-dom";

// import { signOut } from "firebase/auth";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { auth } from "../../Services/firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import Logo from "../../Assets/sodick-logo.png";

const Topbar = (props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        sessionStorage.clear();
        //console.log("Signed out successfully")
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.xsWidth})`,
        ml: props.open
          ? sizeConfigs.sidebar.width
          : sizeConfigs.sidebar.xsWidth,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* <Typography variant="h5" style={{ color: theme.palette.primary.main}}>
            {props && !props.open &&(
              <IconButton sx={{color: colorConfigs.topbar.color}} onClick={props.changeEvent}>
                <img src={logo} width={180} height={50} style={{padding: `10px`}}/>
              </IconButton>
            )}            
          </Typography> */}
        <>
          <IconButton sx={{ color: colorConfigs.topbar.color }}>
            {props && !props.open ? (
              <>
                <MenuIcon onClick={props.changeEvent} />
              </>
            ) : (
              ""
            )}
          </IconButton>
          <img src={Logo} />
        </>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}>
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
          }}>
          <MenuItem onClick={() => logOut()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

/*
 
*/
