import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { darkTheme } from "./theme";
import InfoPage from "./Components/InfoPage";
import SignUp from "./Authentication/SignUp";
import SignIn from "./Authentication/SignIn";
import TrakerInsert from "./Components/TrakerInsert";
import QcPage from "./Components/QcPage";
import ForgotPassword from "./Authentication/ForgotPassword";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AssemblyPage from "./Components/AssemblyPage";
import ReportUpload from "./Components/ReportUpload";

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}

function App() {
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme={true} />
      <ThemeProvider theme={darkTheme}>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/recoverpassword" element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <InfoPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/insertTracker"
            element={
              <PrivateRoute>
                <TrakerInsert />
              </PrivateRoute>
            }
          />
          <Route
            path="/qcTracker"
            element={
              <PrivateRoute>
                <QcPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <PrivateRoute>
                <ReportUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/assembly"
            element={
              <PrivateRoute>
                <AssemblyPage />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
