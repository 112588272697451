import Api from "./Api";

const authHeader = () => {
  let headerDetails = {
    headers: {
      Authorization: sessionStorage.getItem("user"),
      "Content-type": "application/json",
    },
  };
  return headerDetails;
};

const getProductDetails = (data) => {
  return Api.get(`/project/details?category=${data}`, authHeader());
};

const getToolDetails = (data) => {
  return Api.get(`/tool/details?toolId=${data}`, authHeader());
};
const getMachineDetails = (data) => {
  return Api.get(`/machine/details?processName=${data}`, authHeader());
};

const getStaffDetails = (data) => {
  return Api.get(`/staff/details?name=${data}`, authHeader());
};

const getPartDetails = (data) => {
  return Api.get(
    `/part/details?partId=${data.partId}&projectId=${data.projectId}`,
    authHeader()
  );
};

const getDataInset = (data) => {
  return Api.post(`/save/insert`, data, authHeader());
};

const saveQcTracker = (data) => {
  return Api.post(`/save/qc`, data, authHeader());
};

const saveAssemblyTracker = (data) => {
  return Api.post(`/save/assembly`, data, authHeader());
};

const getItemDetails = (data) => {
  return Api.get(`/planned/details?toolId=${data}`, authHeader());
};

const getAcutalDetails = (data) => {
  return Api.get(`/actual/details?toolId=${data}`, authHeader());
};

const getQCDetails = (data) => {
  return Api.get(`/qc/details?toolId=${data}`, authHeader());
};

const getAssemblyDetails = (data) => {
  return Api.get(`/assembly/details?projectId=${data}`, authHeader());
};

const getAssemblyStatus = (data) => {
  return Api.get(`/assembly/status?projectId=${data}`, authHeader());
};

const getAssemblyStage = (data) => {
  return Api.get(`/assembly/stage?projectId=${data}`, authHeader());
};

const getProjetDetailSummary = (data) => {
  return Api.get(
    `https://sap.wegosecure.com/api/moveon/project?projectId=${data}`,
    authHeader()
  );
};

const uploadDocsToS3 = (data) => {
  const headers = {
    Authorization: sessionStorage.getItem("user"),
    'Content-Type': 'multipart/form-data',
  };
  const maxContentLength = 9000000;

  return Api.post(`/async/upload`, data, { headers, maxContentLength, });
  //return Api.post(`/upload`, data, authHeader());
};

const getUploadDetails = () => {
  return Api.get(`/upload/details`, authHeader());
};

const handleLogin = (data) => {
  //console.log("api call");
  return Api.post(`/login`, data, authHeader());
};

export default {
  getProductDetails,
  getPartDetails,
  getItemDetails,
  getProjetDetailSummary,
  getToolDetails,
  getMachineDetails,
  getStaffDetails,
  getDataInset,
  handleLogin,
  saveQcTracker,
  getQCDetails,
  getAssemblyDetails,
  getAssemblyStatus,
  saveAssemblyTracker,
  getAssemblyStage,
  getAcutalDetails,
  uploadDocsToS3,
  getUploadDetails,
};
