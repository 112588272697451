import React, { Component, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Modal } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import DataServices from "../Services/DataServices";
import { Button, TextField } from "@mui/material";
import { Backdrop, Box, Toolbar } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Topbar from "../Layout/SidebarController/Topbar";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

function AssemblyPage() {
  const [statusValue, setStatusValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [OverlayOpen, setOverlayOpen] = useState(false);
  const [stationName, setStationName] = React.useState("");
  const [asmStatus, setAsmStatus] = React.useState("");
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectCatagory, setProjectCatagory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [drawing, setDrawing] = useState("");
  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [staffName, setStaffName] = React.useState(null);
  const [staffNameMenu, setAgeStaffNameMenu] = React.useState([""]);
  const [projectIDValue, setProjectIDValue] = useState("");

  const stationNameMenu = [
    "Station 1",
    "Station 2",
    "Station 3",
  ];

  const asmStatusMenu = [
    "Completed",
    "Rework",
  ];

  const handleClose = (e) => {
    setOverlayOpen(false);
  };

  const ToastMessage = (message, info) => {
    switch (message) {
      case "success":
        toast.success(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      case "error":
        toast.error(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    try {
        if (projectIDValue.length > 4) {
            DataServices.getAssemblyDetails(projectIDValue)
              .then((res) => {
                let data = res?.data?.response?.body?.data;
                setProjectID(data.projectID);
                setProjectName(data.projectName);
                setProjectCatagory(data.projectCategory);
                setStartDate(data.startDate);
                setEndDate(data.endDate);
                setDrawing(data.drawing);
              })
              .catch((err) => {
                ToastMessage("error", "Unable to fetch the data");
                console.log("err", err);
              });
        }
    } catch (error) {
      console.error(error);
    }
  }, [projectIDValue]);

  useEffect(() => {
    if (statusValue && statusValue?.length > 0) {
      setOverlayOpen(true);
    }
  }, [statusValue]);

  useEffect(() => {
    try {
      //console.log("enjnk");
      if (staffName) {
        if (staffName.length > 2) {
          setAgeStaffNameMenu([]);
          DataServices.getStaffDetails(staffName)
            .then((res) => {
              let data = res?.data?.response?.body?.data;
              let dummyData = [];
              // { label: "The Shawshank Redemption", year: 1994 },
              data.map((item, index) => {
                return dummyData.push({
                  label: item.firstName,
                  index: item.empCode,
                });
              });

              setAgeStaffNameMenu(dummyData);
            })
            .catch((err) => {
              ToastMessage("error", "Unable to fetch the data");
              console.log("err", err);
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [staffName]);

  const ResetData = () => {
    setProjectID("");
    setProjectName("");
    setProjectCatagory("");
    setStartDate("");
    setEndDate("");
    setDrawing("");
    setSelectedStaffName("");
    setStaffName(null);
    setStationName("");
    setAsmStatus([]);
    setAgeStaffNameMenu([]);
    setProjectIDValue("")
  };

  const handleSubmit = async () => {
    try {
      if (
        projectIDValue?.length === 0 ||
        projectName?.length === 0 ||
        projectCatagory?.length === 0
      ) {
        ToastMessage("error", "All the fieds are mandatory!");
        return "";
      }
      setLoading(true);
      let dataValues = {
        projectId: projectIDValue,
        projectName: projectName,
        projectCategory: projectCatagory,
        startDate: startDate,
        endDate: endDate,
        station: stationName,
        staffName: selectedStaffName,
        asmStatus: asmStatus,
        asmDrawing: drawing,
      };
      console.log(dataValues)
      DataServices.saveAssemblyTracker(dataValues)
        .then((res) => {
          //console.log("res", res);
          ToastMessage("success", "Data updated successfully");
        })
        .catch((err) => {
          console.log("err", err);
          ToastMessage("error", "Something went wrong");
        })
        .finally(() => {
          ResetData();
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = () => {
    sessionStorage.setItem("sidebarPosition", !open);
    setOpen(!open);
  };

  const handleRest = async () => {
    ResetData();
  };

  const handleToolList = async () => {
    try {
      if (
        projectID?.length === 0
      ) {
        ToastMessage("error", "All the fieds are mandatory!");
        return "";
      }
      setLoading(true);
      DataServices.getAssemblyStatus(projectIDValue)
        .then((res) => {
          //console.log("res", res);
          let data = res?.data?.response?.body?.data;
          let val = data;
          setStatusValue(val);
        })
        .catch((err) => {
          console.log("err", err);
          ToastMessage("error", "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const customColumn = [
    {
      name: "toolId",
      label: "ToolId",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const customOptions = {
    serverSide: false,
    filter: false,
    download: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false,
    viewColumns: false,
    print: false,
    search: false,
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <Modal
        open={OverlayOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "95%",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
          >
            <IconButton
              aria-label="Close"
              color="inherit"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} sm={12} md={12}>
              <MUIDataTable
                title={"Smart Project Management"}
                data={statusValue}
                columns={customColumn}
                options={customOptions}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Topbar open={open} changeEvent={handleChange}/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.xsWidth})`,
          minHeight: "100vh",
          background: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          container
          style={{
            padding: `20px`,
            justifyContent: "center",
          }}
        >
          <Grid
            container
            xs={8}
            sm={8}
            md={8}
            style={{}}
            justifyContent={"center"}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Smart Assembly Tracker
            </Typography>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                Project ID :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project ID"
                variant="outlined"
                fullWidth
                value={projectIDValue}
                onChange={(e) => setProjectIDValue(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                ASM Start Date :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="ASM Start Date"
                variant="outlined"
                fullWidth
                value={startDate}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                Project Category :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project Category"
                variant="outlined"
                fullWidth
                value={projectCatagory}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                ASM End Date :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="ASM End Date"
                variant="outlined"
                fullWidth
                value={endDate}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                Project Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project Name"
                variant="outlined"
                fullWidth
                value={projectName}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                ASM Drawing :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="ASM Drawing"
                variant="outlined"
                fullWidth
                value={drawing}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography style={{ textAlign: "right", marginRight: 20 }}>
                Station :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Station</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stationName}
                  label="Machine ID"
                  onChange={(e) => {
                    if (e.target.value === null) {
                      console.log(e.target.value);
                    } else {
                      setStationName(e.target.value);
                    }
                  }}
                >
                  {stationNameMenu.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography style={{ textAlign: "right", marginRight: 20 }}>
                Staff Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <Autocomplete
                loadingText={"Loading…"}
                loading={true}
                fullWidth
                disablePortal
                id="combo-box-demo"
                options={staffNameMenu}
                clearIcon={false}
                defaultValue={"Staff Name"}
                sx={{}}
                value={selectedStaffName == null ? " " : selectedStaffName}
                onChange={(event, value) => {
                  if (value === null) {
                    console.log(value);
                  } else {
                    setSelectedStaffName(value.label);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      if (e.target.value === null) {
                        console.log(e.target.value);
                      } else {
                        setStaffName(e.target.value);
                      }
                    }}
                    label="Staff Name"
                  ></TextField>
                )}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                Status :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Status"
                variant="outlined"
                fullWidth
                autofocus={false}
                value={asmStatus}
                onChange={(e) => setAsmStatus(e.target.value)}
                select
              >
                {asmStatusMenu.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}></Grid>
          <Grid container xs={12} sm={12} md={12} style={{ marginTop: "45px" }}>
          <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Button variant="contained" fullWidth onClick={handleToolList}>
                Tool List
              </Button>
            </Grid>
            <Grid item xs={1} sm={2} md={2}></Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Button variant="contained" fullWidth onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
            <Grid item xs={1} sm={2} md={2}></Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Button variant="contained" fullWidth onClick={handleRest}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AssemblyPage;
