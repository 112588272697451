import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from '../Services/firebase';
import Logo from '../Assets/sodick-logo.png'


export default function ForgotPassword() { 
    const navigate = useNavigate();
    const [successBox, setSuccessBox]  = React.useState(false);
    const [toastMsg, setToastMsg]= React.useState({
      msg: ``,
      status: ``
    });
    const [email, setEmail] = React.useState('')
    const [isSent, setIsSent] = React.useState(false);

    const handleForgotPassword =async() => {
      try{
        await sendPasswordResetEmail(auth, email)
        setIsSent(true);
      }catch(error){
        console.error('Error signing in:', error);
      }
    }



    const onClose =(e)=>{
        setSuccessBox(false)
      }

    return(
        <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor: colorConfigs.mainBg, height: `100vh`}}
      >
          <Card sx={{ maxWidth: 500, minWidth: 400 }}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img src={Logo} style={{padding: 20, height: '50%', width: '70%'}}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                  <Typography component="h1" variant="body2" style={{textAlign: `center`}}>
                    Forgot your Password?
                  </Typography>
              </Grid>
              {isSent ? (
                <p>Password reset email sent. Check your inbox.</p>
              ):(
                <>
                <form>
                  <Grid container spacing={2}  style={{padding: `10px`}}> 
                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="outlined-required"
                              label="Email"
                              onChange={(e)=> setEmail(e.target.value)}
                              fullWidth
                          />
                      </Grid>                     
                  </Grid>                    
                </form>
                <Grid item xs={12} md={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained" onClick={()=> handleForgotPassword()}>
                      Recover Password
                    </Button>
                </Grid>
                </>
              )}
             
             
              <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center" style={{padding: `10px`}}
                spacing={2}>
                <Grid item xs={4} sm={3} md={3}>
                  <Link onClick={()=>  navigate("/signin")} variant="body2">
                    {"Sign In"}
                  </Link>
                </Grid>
                <Grid item xs={8} sm={9} md={9}>
                  <Link onClick={()=>  navigate("/signup")} variant="body2"  style={{float: 'right'}}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </Grid>
    )
}