import { Box, Toolbar, Modal } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Sidebar from "../Layout/SidebarController/Sidebar";
import Topbar from "../Layout/SidebarController/Topbar";
import React, { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import DataServices from "../Services/DataServices";
import { RotatingLines } from "react-loader-spinner";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function InfoPage() {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicateList, setDuplicateList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [OverlayOpen, setOverlayOpen] = useState(false);
  const [itemsValue, setItemsValue] = useState([]);
  const [actualValue, setActualValue] = useState([]);
  const [qcOverlayOpen, setQCOverlayOpen] = useState(false);
  const [assemblyOverlayOpen, setAssemblyOverlayOpen] = useState(false);
  const [routeOverlay, setRouteOverlay] = useState(false);
  const [projectSummary, setProjectSummary] = useState([]);

  const getProjetDetailSummary = async (data) => {
    try {
      setLoading(true);
      await DataServices.getProjetDetailSummary(data)
        .then((res) => {
          let data = res?.data?.response?.body?.data.parts;
          let val = data || [];
          setValue([]);
          setProjectSummary(val);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getItemDetails = async (data) => {
    try {
      setLoading(true);
      await DataServices.getItemDetails(data)
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data || [];
          setItemsValue(val);
          setRouteOverlay(true);
          setOverlayOpen(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
        await DataServices.getAcutalDetails(data)
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data || [];
          setActualValue(val);
          setRouteOverlay(true);
          setOverlayOpen(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getQCDetails = async (data) => {
    try {
      setLoading(true);
      let arr = [];
      await DataServices.getQCDetails(data)
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data;
          arr.push(val);
          setItemsValue(arr);
          setQCOverlayOpen(true);
          setOverlayOpen(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAssmDetails = async (data) => {
    try {
      setLoading(true);
      let arr = [];
      await DataServices.getAssemblyStage(data)
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data;
          arr.push(val);
          setItemsValue(arr);
          setAssemblyOverlayOpen(true);
          setOverlayOpen(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getProjectList = async (catagory) => {
    try {
      setLoading(true);
      setSelectedText(catagory);
      await DataServices.getProductDetails(catagory)
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data || [];
          setProjectList(val);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    sessionStorage.setItem("sidebarPosition", !open);
    setOpen(!open);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value.toUpperCase());
  };

  const handleClose = (e) => {
    setQCOverlayOpen(false);
    setRouteOverlay(false);
    setAssemblyOverlayOpen(false)
    setOverlayOpen(false);
  };

  const onSearch = (e) => {
    if (searchText.length > 2) {
      getProjectList(searchText);
    }
  };

  const onClickDataChange = async (part, id) => {
    setLoading(true);
    if (id) {
      let data = {
        partId: part.partId,
        projectId: id,
      };
      await DataServices.getPartDetails(data)
        .then((res) => {
          //console.log("getPartDetails", res)
          let data =
            res?.data?.response?.body?.data?.parts?.[0]?.process?.[0]
              ?.subProcess;
          let val = data || [];
          //console.log("res", val)
          setValue(val);
          setProjectSummary([]);
          setDuplicateList(val);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    setLoading(false);
  };

  const column = [
    {
      name: "sNo",
      label: "S.NO",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "toolId",
      label: "Tool ID",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "200px" } }),
      },
    },
    {
      name: "referenceId",
      label: "Reference ID",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "200px" } }),
      },
    },
    {
      name: "toolType",
      label: "Tool NAME",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "taskManager",
      label: "TASK MANAGER",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : "-"}</>;
        },
      },
    },
    {
      name: "startDate",
      label: "Tool Start Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "endDate",
      label: "Tool End Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "drawing",
      label: "Tool Drawing",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "designer",
      label: "Tool Designer",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "reviewForm",
      label: "Tool Review Form",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "bom",
      label: "Bom",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "productionOrder",
      label: "Production Order",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "routeStages",
      label: "ROUTE STAGES",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  getItemDetails(tableMeta.rowData[1]);
                }}>
                View
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "qcStages",
      label: "QC STAGES",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  getQCDetails(tableMeta.rowData[1]);
                }}>
                View
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    serverSide: false,
    filter: false,
    download: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false,
    viewColumns: false,
    print: false,
    search: true,
    onSearchChange: (searchText) => {
      if (searchText == null) {
        setValue(duplicateList);
      } else {
        if (duplicateList.length > 1) {
          const res = duplicateList.filter((obj) =>
            Object.values(obj).some((val) =>
              val.toUpperCase().includes(searchText.toUpperCase())
            )
          );
          setValue(res);
        }
      }
    },
  };

  const customColumn = [
    {
      name: "processName",
      label: "Planned ProcessName",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "machineId",
      label: "Planned M/C ID",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "operatorName",
      label: "Palnned Operator Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "machineStartDate",
      label: "Planned M/C Start Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "machineEndDate",
      label: "Planned M/C End Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
  ];

  const actualColumn = [
    {
      name: "actualProcessName",
      label: "Actual ProcessName",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actualMachineId",
      label: "Actual M/C ID",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "actualOperatorName",
      label: "Actual Operator Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actualMachineStartDate",
      label: "Actual M/C Start Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "actualMachineEndDate",
      label: "Actual M/C End Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
  ];

  const customOptions = {
    serverSide: false,
    filter: false,
    download: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false,
    viewColumns: false,
    print: false,
    search: false,
    onSearchChange: (searchText) => {
      if (searchText == null) {
        setValue(duplicateList);
      } else {
        if (duplicateList.length > 1) {
          const res = duplicateList.filter((obj) =>
            Object.values(obj).some((val) =>
              val.toUpperCase().includes(searchText.toUpperCase())
            )
          );
          setValue(res);
        }
      }
    },
  };

  const customQCColumn = [
    {
      name: "qcResult",
      label: "QC Result",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "qcReport",
      label: "QC Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "qcAction",
      label: "QC Action",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const customAssmColumn = [
    {
      name: "projectId",
      label: "Project ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "asmDrawing",
      label: "ASM Drawing",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "asmStatus",
      label: "ASM Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "station",
      label: "Station",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "staff",
      label: "Staff",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const projectSummarycolumn = [
    {
      name: "sNo",
      label: "S.NO",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "projectName",
      label: "ProjectName",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "projectId",
      label: "Project ID",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "projectManager",
      label: "Project Manager",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : "-"}</>;
        },
      },
    },
    {
      name: "projectStartDate",
      label: "Project Start Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "projectEndDate",
      label: "Project End Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "partId",
      label: "Part Id",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "partName",
      label: "Part Name",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
    {
      name: "partManager",
      label: "Part Manager",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : "-"}</>;
        },
      },
    },
    {
      name: "partStartDate",
      label: "Part Start Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "partEndDate",
      label: "Part End Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "dfmDrawing",
      label: "DFM Drawing",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "dfmReviewForm",
      label: "DFM Review Form",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <IconButton
                  color="secondary"
                  onClick={() => window.open(value, "_blank").focus()}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    },
    {
      name: "dfmDesigner",
      label: "DFM Designer",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "165px" } }),
      },
    },
    {
      name: "assembly",
      label: "Assembly Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  getAssmDetails(tableMeta.rowData[2]);
                }}>
                View
              </Button>
            </>
          );
        },
      },
    },
  ];

  const projectSummaryoptions = {
    serverSide: false,
    filter: false,
    download: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false,
    viewColumns: false,
    print: false,
    search: false,
    onSearchChange: (searchText) => {
      if (searchText == null) {
        setValue(duplicateList);
      } else {
        if (duplicateList.length > 1) {
          const res = duplicateList.filter((obj) =>
            Object.values(obj).some((val) =>
              val.toUpperCase().includes(searchText.toUpperCase())
            )
          );
          setValue(res);
        }
      }
    },
  };

  const SelectedCatagory = useCallback((e) => {
    getProjectList(e.value);
  }, []);

  const selectedProjectID = useCallback((e) => {
    getProjetDetailSummary(e);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Modal
        open={OverlayOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "95%",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
          >
            <IconButton
              aria-label="Close"
              color="inherit"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} sm={12} md={12}>
              {routeOverlay && (
                <MUIDataTable
                  title={"Planned Route Details"}
                  data={itemsValue}
                  columns={customColumn}
                  options={customOptions}
                />
              )}
              {qcOverlayOpen && (
                <MUIDataTable
                  title={"QC Details"}
                  data={itemsValue}
                  columns={customQCColumn}
                  options={customOptions}
                />
              )}
              {assemblyOverlayOpen && (
                <MUIDataTable
                  title={"Assembly Details"}
                  data={itemsValue}
                  columns={customAssmColumn}
                  options={customOptions}
                />
              )}
            </Grid>
            {routeOverlay && (
              <Grid item xs={12} sm={12} md={12}>
                <MUIDataTable
                  title={"Actual Route Details"}
                  data={actualValue}
                  columns={actualColumn}
                  options={customOptions}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>

      <Topbar open={open} changeEvent={handleChange} />
      <Box
        component="nav"
        sx={{
          width: open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
        }}
      >
        <Sidebar
          open={open}
          selectedProjectID={(e) => selectedProjectID(e)}
          changeEvent={handleChange}
          selectedCatagory={SelectedCatagory}
          searchText={selectedText}
          route={projectList}
          onDataChange={onClickDataChange}
          onSearchChange={handleSearch}
          onClickSearch={onSearch}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${
            open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth
          })`,
          minHeight: "100vh",
          background: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        ) : (
          <Grid container spacing={2} style={{ padding: `20px` }}>
            <Grid item xs={12} sm={12} md={12}>
              {value.length === 0 ? (
                projectSummary.length < 1 ? (
                  <Typography>
                    {" "}
                    Please select parts under project to view details
                  </Typography>
                ) : (
                  <MUIDataTable
                    title={"Smart Project Management"}
                    data={projectSummary}
                    columns={projectSummarycolumn}
                    options={projectSummaryoptions}
                  />
                )
              ) : (
                <MUIDataTable
                  title={"Smart Project Management"}
                  data={value}
                  columns={column}
                  options={options}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
