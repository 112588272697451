import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import {  createUserWithEmailAndPassword, sendEmailVerification, getAuth } from 'firebase/auth';
import { auth } from '../Services/firebase';
import { RotatingLines } from  'react-loader-spinner'
import logo from '../Assets/sodick-logo.png'



export default function SignUp() {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const navigate = useNavigate();
    const [successBox, setSuccessBox]  = React.useState(false);
    const [toastMsg, setToastMsg]= React.useState({
        msg: ``,
        status: ``
    });
    const [loading, setLoading] = React.useState(false);

    const handleRegistration =async() => {
      setLoading(true)
        if(email.length > 0 && password.length > 0){
          await createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              sendVerification()
              //console.log(user);
              navigate("/")
              // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const err = errorCode.includes('/') ?  errorCode.split('/')[1] : errorCode
            toast(err);
          });
        }
        setLoading(false)  
    }

    const sendVerification = () => {
      const auth = getAuth()
      sendEmailVerification(auth.currentUser).then((res)=>{
        console.log('sendVerification response', res)
      }).catch((err)=>{
        console.log('sendVerification error',err)
      })
    }

    const onClose =(e)=>{
      setSuccessBox(false)
    }


  return (
    //<div >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{backgroundColor: colorConfigs.mainBg, height: `100vh`}}
        >
           {loading ?  (
              <div>
                    <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="100"
                          visible={true}
                      />
              </div>
            ):(
              <Card sx={{ maxWidth: 500 }}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                 <img src={logo} style={{padding: 20, height: '50%', width: '70%'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography component="h1" variant="body2" style={{textAlign: `center`}}>
                        Register now!
                    </Typography>
                </Grid>
                <form>
                  <Grid container spacing={2}  style={{padding: `10px`}}> 
                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="email"
                              label="Email"
                              onChange={(e)=> setEmail(e.target.value)}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="name"
                              label="Name"
                             onChange={(e)=> setName(e.target.value)}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="password"
                              type='password'
                              label="Password"
                              onChange={(e)=> setPassword(e.target.value)}
                              fullWidth
                          />
                      </Grid>   

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="oupasswordrep"
                              type='password'
                              label="Confirm Password"
                              onChange={(e)=> setConfirmPassword(e.target.value)}
                              fullWidth
                          />
                      </Grid>                       
                  </Grid>                    
                </form>
                <Grid item xs={12} md={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => handleRegistration()}>
                      Sign Up
                    </Button>
                </Grid>
                <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center" style={{padding: `10px`}}
                  spacing={1}>
                  <Grid item xs={4} sm={6} md={6}>
                    <Link onClick={()=>  navigate("/recoverpassword")}  variant="body2">
                      Recover Password
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} >
                    <Link onClick={()=>  navigate("/signin")}  variant="body2" style={{float: 'right'}}>
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            )}
           
            <ToastContainer />
        </Grid>
    //</div>
  );
}