import { ListItemButton, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import React, {useState, useEffect} from 'react'


const SidebarItem = ({ item, onDataChange, projectId }) => {
 
  const handleChange = () => {
    //console.log("projectId", projectId)
    // selectedProject(item);
    onDataChange(item, projectId)
  };

    return (
        item ? (
          <ListItemButton
          onClick={() => handleChange(item)}
            sx={{
              "&: hover": {
                backgroundColor: colorConfigs.sidebar.hoverBg
              },
              //backgroundColor:  colorConfigs.sidebar.activeBg , //pathName == item.path ?  colorConfigs.sidebar.activeBg : `none` ,
              paddingY: "12px",
              paddingX: "24px",
              borderRadius: `25px`,
              margin: `5px`
            }}
          >
           {/*  <ListItemIcon sx={{
              color: colorConfigs.sidebar.color
            }}>
              {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon> */}
            {item.partId}
          </ListItemButton>
        ) : null
      );
}

export default SidebarItem;