import { Backdrop, Box, Toolbar } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Topbar from "../Layout/SidebarController/Topbar";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DataServices from "../Services/DataServices";
import { Grid } from "@mui/material";
import { Button, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const TrakerInsert = () => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectCatagory, setProjectCatagory] = useState("");
  const [toolName, setToolName] = useState("");
  const [scannedToolValue, setScannedToolValue] = useState("");

  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [staffName, setStaffName] = React.useState(null);
  const [staffNameMenu, setAgeStaffNameMenu] = React.useState([""]);

  const [machineID, setMachineID] = React.useState("");
  const [machineIDMenu, setMachineIDMenu] = React.useState([""]);

  const [processName, setProcessName] = React.useState("");
  const processNameMenu = [
    "Lapping Polishing",
    "Surface Grinding",
    "Vertical Milling",
  ];

  useEffect(() => {
    try {
      if (scannedToolValue.length > 16) {
        DataServices.getToolDetails(scannedToolValue)
          .then((res) => {
            let data = res?.data?.response?.body?.data;
            setProjectID(data.projectId);
            setProjectName(data.projectName);
            setProjectCatagory(data.projectCategory);
            setToolName(data.toolName ? data.toolName : "");
          })
          .catch((err) => {
            ToastMessage("error", "Unable to fetch the data");
            console.log("err", err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [scannedToolValue]);

  useEffect(() => {
    try {
      if (processName) {
        //console.log("processName", processName);
        DataServices.getMachineDetails(processName)
          .then((res) => {
            let data = res?.data?.response?.body?.data;
            let dummyData = [];
            data.map((item, index) => {
              return dummyData.push(item.machineId);
            });

            setMachineIDMenu(dummyData);
          })
          .catch((err) => {
            ToastMessage("error", "Unable to fetch the data");
            console.log("err", err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [processName]);

  useEffect(() => {
    try {
      //console.log("enjnk");
      if (staffName) {
        if (staffName.length > 2) {
          setAgeStaffNameMenu([]);
          DataServices.getStaffDetails(staffName)
            .then((res) => {
              let data = res?.data?.response?.body?.data;
              let dummyData = [];
              // { label: "The Shawshank Redemption", year: 1994 },
              data.map((item, index) => {
                return dummyData.push({
                  label: item.firstName,
                  index: item.empCode,
                });
              });

              setAgeStaffNameMenu(dummyData);
            })
            .catch((err) => {
              ToastMessage("error", "Unable to fetch the data");
              console.log("err", err);
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [staffName]);

  const handleChange = () => {
    sessionStorage.setItem("sidebarPosition", !open);
    setOpen(!open);
  };

  const ToastMessage = (message, info) => {
    switch (message) {
      case "success":
        toast.success(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      case "error":
        toast.error(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        scannedToolValue.length === 0 ||
        toolName.length === 0 ||
        projectID.length === 0 ||
        projectName.length === 0 ||
        projectCatagory.length === 0 ||
        processName.length === 0 ||
        machineID.length === 0 ||
        selectedStaffName.length === 0
      ) {
        ToastMessage("error", "All the fieds are mandatory!");
        return "";
      }
      setLoading(true);
      let dataValues = {
        toolId: scannedToolValue,
        toolName: toolName,
        projectId: projectID,
        projectName: projectName,
        projectCategory: projectCatagory,
        processName: processName,
        machineId: machineID,
        staffName: selectedStaffName,
      };
      DataServices.getDataInset(dataValues)
        .then((res) => {
          //console.log("res", res);
          ToastMessage("success", "Data updated successfully");
        })
        .catch((err) => {
          console.log("err", err);
          ToastMessage("error", "Something went wrong");
        })
        .finally(() => {
          ResetData();
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const ResetData = () => {
    setScannedToolValue("");
    setToolName("");
    setProjectID("");
    setProjectName("");
    setProjectCatagory("");
    setProcessName("");
    setMachineID("");
    setSelectedStaffName("");
    setStaffName(null);
    setMachineIDMenu([]);
    setAgeStaffNameMenu([]);
  };

  const handleRest = async () => {
    ResetData();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />

      <Topbar open={open} changeEvent={handleChange} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.xsWidth})`,
          minHeight: "100vh",
          background: colorConfigs.mainBg,
        }}>
        <Toolbar />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          container
          style={{
            padding: `20px`,
            justifyContent: "center",
          }}>
          <Grid
            container
            xs={8}
            sm={8}
            md={8}
            style={{}}
            justifyContent={"center"}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Smart Insert Tracker
            </Typography>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Scanned Tool ID :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Scanned Tool ID"
                variant="outlined"
                fullWidth
                value={scannedToolValue}
                onChange={(e) => setScannedToolValue(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Project ID :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project ID"
                variant="outlined"
                fullWidth
                value={projectID}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Project Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project Name"
                variant="outlined"
                fullWidth
                value={projectName}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Project Category :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Project Category"
                variant="outlined"
                fullWidth
                value={projectCatagory}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Tool Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Tool Name"
                variant="outlined"
                fullWidth
                value={toolName}
                onChange={(e) => setToolName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}>
                Process Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Process Name"
                variant="outlined"
                fullWidth
                autofocus={false}
                value={processName}
                onChange={(e) => setProcessName(e.target.value)}
                select>
                {processNameMenu.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography style={{ textAlign: "right", marginRight: 20 }}>
                Machine ID :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Machine ID
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={machineID}
                  label="Machine ID"
                  onChange={(e) => {
                    if (e.target.value === null) {
                      console.log(e.target.value);
                    } else {
                      setMachineID(e.target.value);
                    }
                  }}>
                  {machineIDMenu.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography style={{ textAlign: "right", marginRight: 20 }}>
                Staff Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <Autocomplete
                loadingText={"Loading…"}
                loading={true}
                fullWidth
                disablePortal
                id="combo-box-demo"
                options={staffNameMenu}
                clearIcon={false}
                defaultValue={"Staff Name"}
                sx={{}}
                value={selectedStaffName == null ? " " : selectedStaffName}
                onChange={(event, value) => {
                  if (value === null) {
                    console.log(value);
                  } else {
                    setSelectedStaffName(value.label);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      if (e.target.value === null) {
                        console.log(e.target.value);
                      } else {
                        setStaffName(e.target.value);
                      }
                    }}
                    label="Staff Name"></TextField>
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            style={{ marginTop: "100px" }}>
            <Grid item xs={1} sm={3} md={3}></Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}>
                Submit
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => handleRest()}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TrakerInsert;
