import React,{useState, memo} from "react";
import { Avatar, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../Routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarCollapse";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";



const Sidebar = (props) => {

   const[projectCatagory, setProjectCatagory] = useState([{
    id: 1,
    name: "DESIGN/SIMULATION",
    selected:false,
    value:"DESIGNSIMULATION"
  },
  {
    id: 2,
    name: "JIG FABRICATION",    
    selected:false,
    value:"JIGFABRICATION"
    },
  {
    id: 3,
    name:"MASS PRODUCTION",    
    selected:false,
    value:"MASSPRODUCTION"
    },
  {
    id: 4,
    name:"OTHERS",    
    selected:false,
    value:"OTHERS"
    },
  {
    id: 5,
    name:"PROTO BUILD",    
    selected:false,
    value:"PROTOBUILD"
    },
  {
    id: 6,
    name:"PROTOTYPE",    
    selected:false,
    value:"PROTOTYPE"
    },
  {
    id: 7,
    name:"SERVICE",    
    selected:false,
    value:"SERVICE"
    },
  {
    id: 8,
    name:"TOOL FABRICATION",    
    selected:false,
    value:"TOOLFABRICATION"
    },
  {
    id: 9,
    name:"TURNKEY",    
    selected:false,
    value:"TURNKEY"}
    ])

  const handleSearchData =()=>{
    props.onClickSearch();
  }
   
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color
          }
        }}
      >
        <List 
        sx={{
          overflowY: "auto",
          margin: 0,
          padding: 0,
          listStyle: "none",
          height: "100%",
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colorConfigs.sidebar.hoverBg,
            outline: '1px solid slategrey'
          }
        }}>
            <Toolbar sx={{ marginBottom: "20px" }}>
            {props && props.open &&(
                  <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                 {/*  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                    <MenuOpenIcon /> 
                  </IconButton> */}
                  <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                  >
                    <IconButton sx={{color: colorConfigs.sidebar.color}} aria-label="menu" onClick={props.changeEvent}>
                      <MenuIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by project"
                      inputProps={{ 'aria-label': 'search by project' }}
                      onChange={props.onSearchChange}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => handleSearchData()}>
                      <SearchIcon />
                    </IconButton>
                </Paper>
                  </Stack>
            )}
            {props && !props.open &&(
              <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                    <MenuIcon /> 
                  </IconButton>
                </Stack>
            )}      
        </Toolbar>
          {/* {props?.route?.length == 0 && (
            <Typography style={{ padding: 5}} align="center"> No records found</Typography>
          )} */}
          {
            projectCatagory.map((item, value) => (
              <Accordion key={value}
                sx={{
                  '&:before': {
                display: 'none',
                  },
                  margin: '0px !important',
                  border: 'none',
                 boxShadow: 'none',
                 backgroundColor: colorConfigs.sidebar.bg,
                  color: colorConfigs.sidebar.color
              }}
              >
                <AccordionSummary onClick={e=>props.selectedCatagory(item)} expandIcon={<ExpandMoreOutlinedIcon />} >
                  <Typography>{item.name}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  {item.value === props.searchText &&
                    <>
                     {props.route.map((route, index) => (
            route ? (
              route.parts ? (
                <SidebarItemCollapse selectedProjectID={e => props.selectedProjectID(e)} item={route} key={index} onDataChange={props.onDataChange}/>
              ) : (
                <SidebarItem item={route}  key={index} onDataChange={props.onDataChange}/>
              )
            ) : null
          ))}
                  </>
        
        }
      </AccordionDetails>
    </Accordion>
            ))
          }
          
         
        </List>
      </Drawer>
    );
  };
  
  export default Sidebar;