import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "./Services/firebase";

const sessionDetails = () => {
  return sessionStorage.getItem(`user`);
};

const PrivateRoute = ({ children }) => {
  const tokenData = sessionDetails();

  return tokenData ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
