import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAPOYYfuR5EuipBAh4XJN30OwX3MR07qtQ",
    authDomain: "moveon-dashboard.firebaseapp.com",
    projectId: "moveon-dashboard",
    storageBucket: "moveon-dashboard.appspot.com",
    messagingSenderId: "137186980329",
    appId: "1:137186980329:web:267b8dac7f5bba4dec9b78"
  };

  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Authentication and get a reference to the service
  export const auth = getAuth(app);
  export default app;