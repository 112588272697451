import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import colorConfigs from "../configs/colorConfigs";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../Services/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import logo from "../Assets/sodick-logo.png";
import DataServices from "../Services/DataServices";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user.accessToken;
          sessionStorage.setItem("user", user);
          let datavalue = {
            email: email,
            firebaseUid: auth.currentUser.uid,
          };
          navigateToDashboard(datavalue);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const err = errorCode.includes("/")
            ? errorCode.split("/")[1]
            : errorCode;
          toast(err);
        });
    } catch (error) {
      console.error("error in login", error);
    }
  };

  const navigateToDashboard = (data) => {
    //navigate("/dashboard");
    //console.log("entry");
    try {
      DataServices.handleLogin(data)
        .then((res) => {
          //console.log(res);
          let tempValue = res?.data?.response?.body?.data?.role;
          if (tempValue) {
            if (tempValue === "insert") {
              setLoading(false);
              navigate("/insertTracker");
            } else if (tempValue === "qc") {
              setLoading(false);
              navigate("/qcTracker");
            } else if (tempValue === "admin") {
              setLoading(false);
              navigate("/dashboard");
            } else if (tempValue === "assembly") {
              setLoading(false);
              navigate("/assembly");
            } else if (tempValue === "upload") {
              setLoading(false);
              navigate("/upload");
            }else {
              setLoading(false);
              navigate("/ij");
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    //<div >
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: colorConfigs.mainBg, height: `100vh` }}>
      {loading ? (
        <div>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      ) : (
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <img
                src={logo}
                alt=""
                style={{ padding: 20, height: "50%", width: "70%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                component="h1"
                variant="body2"
                style={{ textAlign: `center` }}>
                Please sign in to your account below.
              </Typography>
            </Grid>
            <form>
              <Grid container spacing={2} style={{ padding: `10px` }}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    type="password"
                    name="password"
                    id="examplePassword"
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleLogin()}>
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: `10px` }}>
              <Grid item xs={4} sm={6} md={6}>
                <Link
                  onClick={() => navigate("/recoverpassword")}
                  variant="body2">
                  Recover Password
                </Link>
              </Grid>
              <Grid item xs={8} sm={6} md={6}>
                <Link
                  onClick={() => navigate("/signup")}
                  variant="body2"
                  style={{ float: "right" }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <ToastContainer />
    </Grid>
    //</div>
  );
}
