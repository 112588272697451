import { Backdrop, Box, Toolbar, Modal } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Topbar from "../Layout/SidebarController/Topbar";
import React, { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import DataServices from "../Services/DataServices";
import { Grid } from "@mui/material";
import { Button, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDropzone } from 'react-dropzone'
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MUIDataTable from "mui-datatables";

const ReportUpload = () => {
  let uploadText = "Drag & drop the files here, or click to select files";
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [folder, setFolder] = React.useState("");
  const folderMenu = [
    "ASM",
    "BOM",
    "CRF",
    "DFM",
    "IHRF",
    "PROD",
    "QC",
    "TL",
  ];

  const [uploaded, setUploaded] = useState(false)

  const [uText, setUText] = useState(uploadText)

  //const [base64, setbase64] = useState('')
  const [OverlayOpen, setOverlayOpen] = useState(false);
  const [s3Url, setS3Url] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [uploadOverlayOpen, setUploadOverlayOpen] = useState(false);
  const [itemsValue, setItemsValue] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);
  const [value, setValue] = useState([]);
  
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      let text = file?.name ? file.name : uploadText
      setUText(text)
      formData.append("files", file);
    });

    setFormData(formData);
    setUploaded(true);
    // console.log(acceptedFiles);
    // let base64data;
    // acceptedFiles.forEach((file) => {
    //   const reader = new FileReader()
    //   /*   reader.onabort = () => console.log('file reading was aborted')
    //     reader.onerror = () => console.log('file reading has failed')
    //     reader.onload = () => {
    //     // Do whatever you want with the file contents
    //       const binaryStr = reader.result
    //       console.log(binaryStr)
    //     }
    //     reader.readAsArrayBuffer(file) */
    //   let text = file?.name ? file.name : uploadText
    //   setUText(text)
    //   //console.log('name', file.name)
    //   reader.readAsDataURL(file);
    //   reader.onloadend = function () {
    //     base64data = reader.result;
    //     const baseData = base64data.split(';');
    //     //console.log(baseData[1]);
    //     handleFileUpload(baseData[1])

    //   }
    // })
    // setUploaded(true)
  }, [])

  const getFileUploadDetails = async () => {
    try {
      setLoading(true);
      let arr = [];
      await DataServices.getUploadDetails()
        .then((res) => {
          let data = res?.data?.response?.body?.data;
          let val = data;
          arr.push(val);
          setItemsValue(data);
          setUploadOverlayOpen(true);
          setOverlayOpen(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  // const handleFileUpload = (files) => {
  //   //console.log('handle fileupload', files)
  //   setbase64(files)
  // };

  const handleChange = () => {
    sessionStorage.setItem("sidebarPosition", !open);
    setOpen(!open);
  };

  const ToastMessage = (message, info) => {
    switch (message) {
      case "success":
        toast.success(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      case "error":
        toast.error(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        return null;
    }
  };

  const ResetData = () => {
    setFileName("");
    setFolder("");
    setUText(uploadText);
    setFormData("");
  };

  const handleRest = async () => {
    ResetData();
  };
  const openModalWithUrl = (url) => {
    setS3Url(url);
    setOverlayOpen(true);
  };
  const handleClose = (e) => {
    setOverlayOpen(false);
  };
  const handleSubmit = async () => {
    try {
      if (
        fileName.length === 0 ||
        folder.length === 0
      ) {
        ToastMessage("error", "All the fieds are mandatory!");
        return "";
      }
      setLoading(true);
      formData.append('fileName', fileName);
      formData.append('folder', folder);
      // let dataValues = {
      //   fileName: fileName,
      //   folder: folder,
      //   fileBase64: base64,
      // };
      console.log("formData", formData)
      DataServices.uploadDocsToS3(formData)
        .then((res) => {
          //console.log("s3Url", res?.data?.response?.body?.data?.s3Url);
          ToastMessage("success", "File submitted for upload successfully");
          //openModalWithUrl(res?.data?.response?.body?.data?.s3Url);
        })
        .catch((err) => {
          console.log("err", err);
          ToastMessage("error", "Something went wrong");
        })
        .finally(() => {
          ResetData();
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // const copyToClipboard = () => {
  //   if (s3Url) {
  //     navigator.clipboard.writeText(s3Url).then(() => {
  //       alert("URL copied to clipboard");
  //     }).catch((error) => {
  //       console.error("Error copying to clipboard: ", error);
  //     });
  //   }
  // };

  const customColumn = [
    {
      name: "toolId",
      label: "FileName",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "s3Url",
      label: "S3URL",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
      },
    },
  ];

  const customOptions = {
    serverSide: false,
    filter: false,
    download: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false,
    viewColumns: false,
    print: false,
    search: true,
    onSearchChange: (searchText) => {
      if (searchText == null) {
        setValue(duplicateList);
      } else {
        if (duplicateList.length > 1) {
          const res = duplicateList.filter((obj) =>
            Object.values(obj).some((val) =>
              val.toUpperCase().includes(searchText.toUpperCase())
            )
          );
          setValue(res);
        }
      }
    },
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />

      <Modal
        open={OverlayOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "95%",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
          >
            <IconButton
              aria-label="Close"
              color="inherit"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} sm={12} md={12}>
              {uploadOverlayOpen && (
                <MUIDataTable
                  title={"Uploaded File Details"}
                  data={itemsValue}
                  columns={customColumn}
                  options={customOptions}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Topbar open={open} changeEvent={handleChange} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.xsWidth})`,
          minHeight: "100vh",
          background: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          container
          style={{
            padding: `20px`,
            justifyContent: "center",
          }}
        >
          <Grid
            container
            xs={8}
            sm={8}
            md={8}
            style={{}}
            justifyContent={"center"}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Smart Report Upload
            </Typography>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right", marginRight: 20 }}
              >
                File Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                id="outlined-basic"
                label="Paste the file name here"
                variant="outlined"
                fullWidth
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6} style={{ marginTop: "40px" }}>
            <Grid item xs={4} sm={3} md={3} alignSelf={"center"}>
              <Typography style={{ textAlign: "right", marginRight: 20 }}>
                Folder :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select the folder
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={folder}
                  label="Folder"
                  onChange={(e) => {
                    if (e.target.value === null) {
                      console.log(e.target.value);
                    } else {
                      setFolder(e.target.value);
                    }
                  }}
                >
                  {folderMenu.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={8} md={8} style={{ marginTop: "40px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <TextField
                  id="outlined-basic"
                  value={uText}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            style={{ marginTop: "45px" }}
          >
            <Grid item xs={1} sm={2} md={2}></Grid>
            <Grid item xs={3} sm={2} md={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}
              >
                Upload
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={3} sm={2} md={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => handleRest()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={3} sm={2} md={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  getFileUploadDetails();
                }}
              >
                View
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* <Modal
        open={OverlayOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "95%",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
          >
            <IconButton
              aria-label="Close"
              color="inherit"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            {s3Url && (
              <Typography>
                <strong>S3 URL:</strong>{" "}
                {s3Url}
              </Typography>
            )}
            <IconButton
              aria-label="Close"
              color="inherit"
              sx={{ position: "absolute", top: 25, right: 150 }}
              onClick={() => copyToClipboard()}
            >
              <FileCopyIcon />
            </IconButton>
          </Grid>
        </Box>
      </Modal> */}
    </Box>
  );
};

export default ReportUpload;
